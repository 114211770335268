import Vue from 'vue'
import ConfirmDialog from '@/components/common/ConfirmDialog'

import cBtn from '@/components/base/c-btn'
import cIcon from '@/components/base/c-icon'

Vue.component('confirm-dialog', ConfirmDialog);

Vue.component('c-btn', cBtn);
Vue.component('c-icon', cIcon);
