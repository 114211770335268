export default {
    formatPrice(value) {
        if (!value) { value = 0 }
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value)
    },
    formatInteger(value) {
        if (!value) { return null }
        return new Intl.NumberFormat('it-IT').format(value)
    },
    round(value, places = 2) {
        if (!value) { return null }
        return +(Math.round(value + 'e+' + places) + 'e-' + places)
    },
    percent(count, total, placesRound = 2) {
        if (!total || !count) { return 0 }

        const resutl = (count / total) * 100;

        return +(Math.round(resutl + 'e+' + placesRound) + 'e-' + placesRound)
    }
}
