export default {

    /**
     Randomize the arrangement
   https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
   * */
    shuffle(array) {
        let currentIndex = array.length; let randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    },

    /**
   mantain the Odd positions in the array and randomly shuffle even positions
   * */
    shuffleArrayEvenPositions(array) {
        let i = array.length - 1;
        i = (i % 2 === 0) ? i - 1 : i;

        for (; i > 0; i -= 2) {
            var j = Math.floor(Math.random() * (i + 1));

            if (j % 2 === 0) {
                j = (j > 0) ? j - 1 : j + 1;
            }

            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
}
