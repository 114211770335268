const state = () => ({
    status: false
})

const mutations = {
    SET(state, status) {
        state.status = status
    }
}

const actions = {
    start({ commit }) {
        commit('SET', true)
    },
    end({ commit }) {
        commit('SET', false)
    }
}

const getters = {
    getStatus: (state) => {
        return state.status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
