import store from "@/store";

export default (to, from, next) => {
    const user = store.getters.loggedInUser

    if (user?.token && store.getters.isAdmin) {
        next()
    } else {
        localStorage.removeItem('userInfo')
        next('/app/sessions/sign-in-two')
    }
}
