export default (to, from, next) => {
    if (
        localStorage.getItem('userInfo')?.length
    ) {
        next()
    } else {
        localStorage.removeItem('userInfo')
        next('/app/sessions/sign-in-two')
    }
}
