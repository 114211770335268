import axiosInstance from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        users: [],
        postsHashId: {} // postsHashId[id] === indexOfPost
    },
    getters: {
        getUsers: state => state.users
        /* getPostsOrdered: (state, getters, rootState, rootGetters) => {
            const lang = rootGetters["languages/getLang"];

            return state.posts
                .sort((a, b) => parseInt(a.attributes.order) > parseInt(b.attributes.order) ? 1 : -1)
                .filter(post => {
                    if (post.attributes.lang === lang) return true;
                });
        }, */

    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        }
    },
    actions: {
        async loadUsers({
            commit,
            state
        }) {
            try {
                if (state.users.length) return state.users

                const users = await axiosInstance.get('users')
                // .get('users?filter[MeNot]=1')

                commit('setUsers', users.data.data)

                return state.users
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
