const state = () => ({
    color: '',
    message: '',
    snackbar: false
})

const mutations = {
    SHOW_SNACKBAR(state, snackbar) {
        state.color = snackbar.color
        state.message = snackbar.message
        state.snackbar = true
    },
    HIDE_SNACKBAR(state) {
        state.snackbar = false
    },
    RESET_SNACKBAR(state) {
        state.color = ''
        state.message = ''
    }
}

const actions = {
    showSnackbar({ commit }, { color, message }) {
        return commit('SHOW_SNACKBAR', { color, message })
    },
    success({ commit }, message) {
        return commit('SHOW_SNACKBAR', { color: 'success', message })
    },
    error({ commit }, message) {
        return commit('SHOW_SNACKBAR', { color: 'error', message })
    },
    hideSnackbar({ commit }) {
        return commit('HIDE_SNACKBAR') && commit('RESET_SNACKBAR')
    }
}
const getters = {
    getMessage: (state) => {
        return state.message
    },
    getColor: (state) => {
        return state.color
    },
    getStatus: (state) => {
        return state.snackbar
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
