import Vuex from 'vuex'
import Vue from 'vue'
import largeSidebar from './modules/largeSidebar'
import compactSidebar from './modules/compactSidebar'
import themeConfig from './modules/themeConfig'
import authData from './modules/authData'
import verticalSidebar from './modules/verticalSidebar'

// import createPersistedState from "vuex-persistedstate";
import snackbar from './modules/snackbar';
import loading from './modules/loading';
import permissions from './modules/permissions';
import languages from './modules/languages';
import cache from './modules/cache';
import statistics from './modules/statistics';
import configGeneral from './modules/configGeneral';

import cacheRecognitios from './modules/cacheRecognitios';
// Load Vuex
Vue.use(Vuex)

// Create store
const store = new Vuex.Store({
    modules: {
        largeSidebar,
        compactSidebar,
        themeConfig,
        authData,
        verticalSidebar,
        snackbar,
        loading,
        permissions,
        languages,
        cache,
        statistics,
        cacheRecognitios,
        configGeneral
    }/*,
    plugins: [createPersistedState()] */
});

export default store;
