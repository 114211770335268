export default {
    languageToDisplay(value) {
        switch (value) {
        case 'es':
            return 'Español';
        case 'en':
            return 'English';
        }
    },
    /**
   * @params: a text with possibles tags
   * @return: a text withouth the text tags, only the component innerHTML recursively
   * */
    stripTags(text) {
        return text.replace(/(<([^>]+)>)/ig, "");
    }
}
