
const state = () => ({
    lang: localStorage.getItem('lang') ?? 'es'
})

const mutations = {

    SET_LANG(state, lang) {
        state.lang = lang
    }
}
const actions = {
    updateLang({ commit, dispatch }, lang) {
    // Vue.prototype.$nuxt.$vuetify.lang.current
        commit('SET_LANG', lang)
        window.localStorage.setItem("lang", lang);
        // dispatch('catalog/findAllCategories', lang, { root: true })
    }

}
const getters = {
    getLang: (state) => {
        return state.lang
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
