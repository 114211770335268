import Vue from 'vue'

import dateHelpers from '@/helpers/date'
import numberHelpers from '@/helpers/numbers'
import arrayHelpers from '@/helpers/array'
import generalHelpers from '@/helpers/general'

export default {
    install: () => {
        Vue.prototype.dateHelper = dateHelpers
        Vue.dateHelper = dateHelpers

        Vue.prototype.numberHelper = numberHelpers
        Vue.numberHelper = numberHelpers

        Vue.prototype.arrayHelper = arrayHelpers
        Vue.arrayHelper = arrayHelpers

        Vue.prototype.generalHelper = generalHelpers
        Vue.generalHelper = generalHelpers
    }
}
